/* -------------NAVBAR -------------------*/
.navbar_main{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
}
.navbar_logo img{
    height: 5rem;
}
.navbar_logo--superadmin img{
    height: 3rem;
}
.navbar_year--input{
    color: white !important;
}
.navbar_year--label{
    color: #fff !important;
 }
@media (max-width: 957px)
{
    .navbar_main{
        height:3rem;
    }
    .navbar_logo{
        height: 3rem;
    }
    .navbar_logo img{
        width: 100%;
        height: 3rem;
    }
    .navbar_logo--superadmin img{
        padding: .5rem 0;
    }
    .navbar_year--label{
       color: #fff !important;
       font-size:12px !important;
    }
    .navbar_year--input{
        color: white !important;
        height: 2rem;
        font-size: 12px !important;
        padding: 1rem;
        display: flex;
        align-items: center;
    }
}
/* -------------NAVBAR END----------------*/

#contentBox .contentBox_content{
    text-align: initial;
    margin-top: 10%;
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 600px)
{
    #contentBox .contentBox_content{
        padding:1rem
    }
}
/* -------------LOADER----------------*/
.loader{
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
}

@media (min-width:1960px){
    .ls-text-center td{
        text-align: center;
    }
}